//out: ../../../css/modules/blockuserinfo/blockuserinfo.css

@import "../../compass";
@import "../../theme_variables";
.header_user_info {
	float: right;
	margin: 0 -5px 0 15px;
	span {padding: 0 1px;}
	a {
		i {
			font-size: 18px;
	    padding-right: 7px;
	    position: relative;
	    top: 2px;		
		}
		cursor: pointer;
		display: inline-block;
		line-height: 35px;
		padding: 0 5px;
		text-transform: uppercase;
		@media (max-width: $screen-xs - 1) {

		}
		&:hover, &.active {

		}
	}
}
